import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/kernel-v2/kernel-v2/src/modules/layouts/default_layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const StatusBanner = makeShortcode("StatusBanner");
const Flash = makeShortcode("Flash");
const Card = makeShortcode("Card");
const AudioPlayer = makeShortcode("AudioPlayer");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "-value",
      "style": {
        "position": "relative"
      }
    }}>{`💯 Value`}<a parentName="h1" {...{
        "href": "#-value",
        "aria-label": " value permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h1>
    <p>{`What is value? This is, in a sense, the ultimate question. So, how can we use our new thinking skills based on complementary opposites to break it down and come to some useful insights? `}</p>
    <StatusBanner warning mdxType="StatusBanner">
      <p>{`💡 Take a moment to pause here and consider it for yourself before reading further...`}</p>
    </StatusBanner>
    <p>{`We can begin by asking, "What destroys value?" Some suggestions include: inefficiency, fear, anger, deception, violence, and envy.`}</p>
    <p>{`Hopefully you can begin to see why playing with pattern is such a valuable and powerful means of thinking. Instead of egotistically presupposing we can drive straight into a coherent answer, the idea is to explore the space of seeming opposition as this, more often than not, sheds light in surprising ways on the original question. In the same way that building trustless protocols means defining clearly what it means to cheat; we can come to understand far more about value by considering first what it is not.`}</p>
    <p>{`If it is not disagreement, fear, deception and envy, then it seems like `}<strong parentName="p">{`trust in clearly shared truths`}</strong>{` lies at the core of how we might generate valuable interactions between people.`}</p>
    <h2 {...{
      "id": "consensus",
      "style": {
        "position": "relative"
      }
    }}>{`Consensus`}<a parentName="h2" {...{
        "href": "#consensus",
        "aria-label": "consensus permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <p>{`So, how do we create shared truth? The simple answer is: `}<em parentName="p">{`through fiction`}</em>{`. People tend to look down on fiction, even though it is really just the lie which reveals truth. What matters most in terms of true value generation is the efficiency of the language you employ to tell your fiction. `}</p>
    <p>{`The legal fiction of the firm improves our ability to generate value based on new kinds of transactions by several orders of magnitude. It has also ended up being extractive and corrupt, because legal text must be parsed and interpreted by humans, who are error-prone and easy to manipulate; and because legal text is - in fact - enforced by the threat of violence which relies on the asymmetric power of a nation state.`}</p>
    <p>{`Blockchains implement a new kind of mathematical consensus fiction. The core question remains: how many people can reliably share in the truth your fiction reveals, for this defines the constraints of what kinds of value can be generated.`}</p>
    <Flash mdxType="Flash">
      <Card mdxType="Card">
        <p>{`Prompt: How can we develop valuable interactions between people?`}</p>
        <p>{`By creating trust in clearly shared truths.`}</p>
      </Card>
      <Card mdxType="Card">
    
Prompt: As opposed to the legal fiction of the firm, what is the fiction that reveals the truth underlying the value generated by blockchains?
        <p>{`Mathematical consensus (extra points if you said "cryptographically secure").`}</p>
      </Card>
    </Flash>
    <h2 {...{
      "id": "narrative",
      "style": {
        "position": "relative"
      }
    }}>{`Narrative`}<a parentName="h2" {...{
        "href": "#narrative",
        "aria-label": "narrative permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <p>{`Tribal narratives allowed truth to be shared among ~150 people. These evolved into religious myths around the time of the agricultural revolution. It's no coincidence that, concomitant with the spread of the first major religious myths, came the development of writing; the primary use of which was tracking debt. Myth alone is not enough to hold societies larger than your average tribe together: it needs to be paired with a shared record, built around succinct and significant symbols.`}</p>
    <p><strong parentName="p">{`Our ability to create value has always been tied to the ways in which we tell stories about, and with, our shared records`}</strong>{`. However, prior to the feedback loop outlined in `}<a parentName="p" {...{
        "href": "/learn/module-0/trust"
      }}>{`trust`}</a>{`, the record was maintained by `}<em parentName="p">{`someone`}</em>{`, which gives them enormous power and means everyone else is incentivized to try and manipulate them.`}</p>
    <p>{`Because blockchains allow us to define succinctly our shared truths, `}<strong parentName="p">{`and because the record itself is shared`}</strong>{` across all participants, there is a whole new "trust space" we can explore, searching for more valuable kinds of transactions impossible within merely legal fictions. `}</p>
    <p>{`Take a moment to reflect on this paradox: trustless media enable us to trust each other directly. Similarly, we each have equal value precisely because we are all different. Hopefully you are beginning to see why complementary opposites are necessary to navigate what Shunryu Suzuki calls the reality of "things-as-it-is". As he says in the second talk of `}<a parentName="p" {...{
        "href": "https://terebess.hu/zen/mesterek/Branching-Streams.pdf"
      }}><em parentName="a">{`Branching Streams Flow in the Darkness`}</em></a>{`:`}</p>
    <blockquote>
      <p parentName="blockquote">{`Another way to explain reality is by differentiation. Differentiation is equality, and things have equal value because they are different. If men and women are the same, then the distinctions between men and women have no value. Because men and women are different, men are valuable as men and women are valuable as women. To be different is to have value `}{`[...]`}{` The usual understanding is that differentiation is the opposite of equality, but our understanding is that they are the same thing.`}</p>
    </blockquote>
    <h2 {...{
      "id": "listen",
      "style": {
        "position": "relative"
      }
    }}>{`Listen`}<a parentName="h2" {...{
        "href": "#listen",
        "aria-label": "listen permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <AudioPlayer src="https://kernel-audio.s3.amazonaws.com/M1E2+-+Value.mp3" mdxType="AudioPlayer" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      